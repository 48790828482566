@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --white-color: #fefefb;
  --black-color: #090105;
  --blue-color: #2aa1da;
  --yellow-color: #feb904;

  --navbar-size: 10rem;
  --padding-g: 10rem;
  --padding-p: 2rem;
}

* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-transform: none;
  transition: all 0.2s ease-out;
}

*::-webkit-scrollbar {
  display: none;
}

a {
  color: var(--white-color);
  text-decoration: none;
  cursor: pointer;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-padding-top: 7rem;
  scroll-behavior: smooth;
}

/* navbar starts */

.navbar {
  width: 100vw;
  height: var(--navbar-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem var(--padding-g);
  position: fixed;
  z-index: 3000;
}

.with-bg {
  background-color: rgb(0, 0, 0);
}

.no-bg {
  background-color: unset;
}

.navbar .main-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.contact-nav-btn {
  border: 0.05rem solid var(--white-color);
}

.navbar .main-menu li {
  font-size: 1.6rem;
  color: var(--white-color);
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
}

.navbar .main-menu li:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.dropdown-li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  position: relative;
  z-index: 200;
}

.dropdown-li .head-li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  position: relative;
  z-index: 200;
}

.dropdown-li ul {
  position: absolute;
  top: 0rem;
  right: 0;
  left: 0;
  z-index: -100;
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  opacity: 0;
}

.mentorship-dropdown:hover ul {
  opacity: 1;
  z-index: 100;
  top: 100%;
}

.chanllenges-dropdown:hover ul {
  opacity: 1;
  z-index: 100;
  top: 100%;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
}

.select-lang {
  padding: 0.5rem 1rem;
  background-color: unset;
  font-size: 1.6rem;
  color: var(--white-color);
  cursor: pointer;
  position: relative;
}

.select-lang .head-li {
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-lang .lang-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  margin-top: 1rem;
  border: 0.02rem solid rgba(255, 255, 255, 0.4);
  padding: 0.5rem 1rem;
}

.select-lang .lang-list li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.select-lang .lang-list li:hover {
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--black-color);
}

.select-lang .lang-list li img {
  width: 3rem;
  height: 2rem;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.logo img {
  height: 6rem;
}

.logo label {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: start;
}

.logo label span:first-child {
  color: var(--blue-color);
  text-transform: uppercase;
}

.logo label span:last-child {
  color: var(--yellow-color);
  text-transform: uppercase;
}

.menu-image {
  height: 3rem;
  position: relative;
  z-index: 3000;
}

.menu-btn {
  border: none;
  cursor: pointer;
  background: unset;
  display: none;
  height: 4rem;
}

.menu-icon {
  color: var(--white-color);
  height: 4rem;
}

.menu-mobile {
  display: none;
}

@media (max-width: 480px) {
  .menu-btn {
    display: block;
  }
  .menu {
    display: none;
  }
  .menu-mobile {
    position: absolute;
    top: var(--navbar-size);
    right: -100vw;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.95);
    padding: 1rem 3rem;
    display: none;
  }
  .show-menu {
    right: 0 !important;
    display: block;
    animation: slideFromRight 1s forwards;
  }

  .main-menu-mobile {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    font-size: 1.7rem;
  }

  .main-menu-mobile li {
    margin: 1rem 0 !important;
  }

  .main-menu-mobile > div {
    margin: 0 !important;
    padding: 0!;
  }

  .navbar .menu-mobile .socials {
    justify-content: flex-start;
    gap: 1rem;
    height: fit-content;
    padding: 0 !important;
  }

  .navbar .menu-mobile .socials img {
    height: 3.5rem;
    margin: 0;
  }

  .navbar .menu-mobile .lang-list-mobile {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 1rem;
  }
  .navbar .menu-mobile .lang-list-mobile img {
    height: 2.5rem;
    margin: 0;
  }
  .main-menu-mobile .contact-nav-btn {
    border: unset !important;
  }
  .navbar {
    padding: 2rem;
  }

  .navbar .logo {
    gap: 0;
  }

  .navbar .logo img {
    height: 5rem;
  }
  .navbar .logo label {
    font-size: 2rem;
  }
}

/* navbar ends */

/* home starts */

.div-container {
  width: 100%;
  height: fit-content;
}

.loading {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #2aa1da;
}

.home-container {
  width: 100%;
  height: 100vh;
  background-image: url("../images/home.webp");
  background-color: var(--black-color);
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-sentence {
  width: 50rem;
  height: 20rem;
}

.home-container p {
  color: var(--white-color);
  font-size: 1.7rem;
  text-align: center;
  position: absolute;
  bottom: 2rem;
}

.see-results-btn {
  padding: 1rem 2rem;
  background-color: var(--yellow-color);
  color: var(--black-color);
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: none;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.93);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0rem;
  gap: 3rem;
}

@media (max-width: 480px) {
  .hero-sentence {
    width: 80%;
    height: unset;
  }
  .home-container {
    background-image: url("../images/home.jpg");
    background-size: cover;
    background-position: right;
  }
}

/* home ends */

/* results starts */

.results-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.result-section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  position: relative;
}

.results-s1 {
  background-image: url("../images/blackbg.jpg");
}

.feedbacks-s1 {
  background-image: url("../images/blackbg2.jpg");
}

.calcul8h-s1 {
  background-image: url("../images/blackbg.jpg");
}

.overlay2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0rem;
}

.section-head-title {
  text-align: center;
  color: var(--yellow-color);
  font-size: 4rem;
  text-transform: capitalize;
  margin-top: var(--navbar-size);
  letter-spacing: 0.5rem;
  position: relative;
}

.section-head-title-2 {
  letter-spacing: unset !important;
}

.results-slogan {
  text-align: center;
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 2rem;
  margin-top: 1rem;
  padding: 0 2rem;
}

.slide-container {
  width: 100vw;
  height: calc(100vh - 2 * var(--navbar-size) - 2rem);
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 2rem;
}

.nav-btn {
  width: 4rem;
  height: 4rem;
  border: none;
  border-radius: 50%;
  background-color: var(--blue-color);
  color: var(--white-color);
  cursor: pointer;
}

.nav-icon {
  color: var(--white-color);
  font-size: 2rem;
}

.silder {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: calc(3 * 22rem + 3rem);
  overflow: scroll;
  white-space: nowrap;
}

.imageSlider {
  width: 22rem;
  border-radius: 1rem;
  border: none;
}

@media (max-width: 480px) {
  .section-head-title {
    font-size: 3rem;
  }
  .slide-container {
    gap: 1rem;
  }

  .silder {
    width: calc(100vw - 12rem);
  }

  .imageSlider {
    width: calc(100vw - 14rem);
  }
}

/* results ends */

/* feedbacks starts */

.feedbacks-video {
  width: 70%;
  height: calc(100vh - 2 * var(--navbar-size));
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem;
  border: 0.05rem dashed var(--yellow-color);
}

.feedbacks-video iframe {
  width: 100%;
  height: 100%;
}

/* feedbacks ends */

/* toastify starts */

.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 50px !important;
  background: #f0f9fa !important;
}

.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
}
.Toastify__toast > button > svg {
  display: none;
}

/* tostify ends */

/* calcul8h starts */

.caclut8h-part-container {
  width: 100%;
  height: calc(100vh - 2 * var(--navbar-size) - 5rem);
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 2rem 10rem;
}

.video-part-desc-container,
.video-part-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  flex-direction: column;
}

.video-part-desc-container {
  padding-right: 10rem;
}

.video-part-desc-container-2 {
  padding-right: unset !important;
}

.video-part-desc-container p {
  color: var(--white-color);
  font-size: 2rem;
  white-space: pre-line;
}

.video-part-container {
  border: 0.05rem dashed var(--yellow-color);
  padding: 1rem;
}

.video-part-container iframe {
  width: 100%;
  height: 100%;
}

.video-placeholder {
  height: 100%;
}

.video-part-desc-container,
.video-part-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.video-part-desc-container {
  animation: slideFromLeft 1s forwards;
}

.video-part-container {
  animation: slideFromRight 1s forwards;
}

.video-part-container-2 {
  animation: slideFromLeft 1s forwards !important;
}

.video-part-desc-container-2 {
  animation: slideFromRight 1s forwards !important;
}

.calcul8h-s2 {
  background-image: url("../images/bluebg.jpg");
}

.overlay3 {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
}

@media (max-width: 480px) {
  .chess-container {
    height: fit-content;
  }
  .chess-s1,
  .chess-s2,
  .calcul8h-s1,
  .calcul8h-s2 {
    height: fit-content;
    min-height: calc(1.2 * 100vh);
    padding: 2rem;
  }
  .overlay-chess {
    min-height: calc(1.2 * 100vh);
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .caclut8h-part-container {
    height: 100%;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
  }
  .caclut8h-part-container-2 {
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    height: 100%;
  }
  .video-part-desc-container,
  .video-part-container {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-right: unset;
  }

  .video-part-container {
    height: 35vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem !important;
  }

  .video-part-container iframe {
    height: 100%;
    width: 100%;
  }
  .video-part-desc-container {
    height: fit-content;
  }
  .video-part-desc-container p {
    font-size: 1.5rem;
    text-align: left;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* calcul8h ends */

/* one to one starts */

.one-to-one-container {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 2rem;
  padding-top: 2rem;
}

.onetoone-desc {
  padding: 2rem;
  border: 0.05rem dashed var(--yellow-color);
  color: var(--white-color);
  width: 50%;
  font-size: 2rem;
  white-space: pre-line;
  animation: slideFromRight 1s forwards;
}

.register-btn {
  font-size: 2rem;
  background-color: var(--blue-color);
  color: var(--white-color);
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  animation: slideFromLeft 1s forwards;
  margin: 2rem 0;
  width: fit-content;
}

.register-btn-condition {
  color: var(--blue-color);
  text-decoration: underline;
}

.copy-register {
  position: absolute;
  top: 20rem;
  right: 10rem;
  width: fit-content !important;
  background-color: unset !important;
  padding: 0 !important;
}

.copy-desc {
  width: 60%;
  padding: 2rem;
  margin: 2rem;
  border: 0.05rem dashed var(--yellow-color);
  animation: slideFromRight 1s forwards;
}

.copy-desc p {
  font-size: 2rem;
  color: var(--white-color);
  text-align: left;
}
.copy-desc h2 {
  font-size: 3rem;
  text-transform: uppercase;
  color: var(--yellow-color);
  margin-top: 2rem;
}
.copy-desc ul {
  font-size: 2rem;
  color: var(--white-color);
  margin-top: 2rem;
  margin-left: 2rem;
}
.copy-desc ul li span {
  color: var(--blue-color);
}
.copy-desc ul li a {
  color: var(--blue-color) !important;
  text-decoration: underline;
}

@media (max-width: 480px) {
  .onetoone-desc {
    padding: 2rem;
    border: 0.05rem dashed var(--yellow-color);
    color: var(--white-color);
    width: 90%;
    font-size: 1.5rem;
    white-space: pre-line;
    animation: slideFromRight 1s forwards;
  }

  .copy-desc {
    width: 90%;
    margin: 1rem;
  }

  .copy-desc p {
    font-size: 1.5rem;
  }
  .copy-desc h2 {
    font-size: 2rem;
    margin-top: 1rem;
  }
  .copy-desc ul {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-left: 2rem;
  }
}

/* one to one ends */

/* chess method starts */

.chess-s1 {
  background-image: url("../images/chessbg1.jpg");
}

.chess-s2 {
  background-image: url("../images/chessbg1.jpg");
}

.chess-register {
  height: fit-content;
  width: 100%;
  padding: 2rem;
  background-color: var(--black-color);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

/* chess method ends */

/* about starts */

.overlay-about {
  justify-content: center;
  align-items: center;
}

.about-text {
  font-size: 1.7rem;
  width: 60%;
  color: var(--white-color);
  text-align: left;
  margin-top: 2rem;
  padding: 2rem;
  border: 0.05rem dashed var(--yellow-color);
  overflow: scroll;
  animation: slideFromRight 1s forwards;
  white-space: pre-line;
}

.founder-card {
  width: 42rem;
  height: 50rem;
  background-color: var(--white-color);
  border-radius: 1rem;
  border: none;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-card {
  width: 100%;
  height: 15rem;
  background-color: var(--blue-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.bottom-card {
  width: 100%;
  height: 35rem;
  background-color: var(--white-color);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.founder-pic {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  border: 0.5rem solid var(--white-color);
  position: absolute;
  top: 4rem;
  z-index: 10;
}

.founder-name {
  font-size: 2.5rem;
  text-align: center;
  margin-top: 12rem;
}

.founder-desc {
  font-size: 1.4rem;
  padding: 1rem;
  text-align: center;
}

.founders-container {
  margin-top: 2rem;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

@media (max-width: 480px) {
  .about-text {
    width: 90%;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1rem;
  }

  .founders-container {
    flex-direction: column;
    padding: 2rem;
  }

  .founder-card {
    width: 100%;
  }

  .top-card {
    width: 100%;
  }

  .bottom-card {
    width: 100%;
  }

  .founder-pic {
    width: 17rem;
    height: 17rem;
  }

  .founder-name {
    font-size: 2rem;
    margin-top: 10rem;
  }

  .founder-desc {
    font-size: 1.4rem;
  }
}

/* about ends */

/* contact starts */

.contact-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--black-color);
  gap: 4rem;
  padding-top: calc(var(--navbar-size) + 3rem);
}

.contact-container h1 {
  font-size: 3.5rem;
  color: var(--yellow-color);
}

.contact-container form {
  border: 0.05rem dashed var(--yellow-color);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 50%;
  animation: slideFromRight 1s forwards;
}

.fields-2,
.fields-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.fields-1 textarea {
  height: 18rem;
  resize: unset;
}

.contact-container form .contact-input {
  padding: 1.5rem;
  width: 100%;
  border: none;
  border-radius: 0.3rem;
  font-size: 1.5rem;
}

.submit {
  min-width: 15rem;
  padding: 1rem 3rem;
  background-color: #2aa1da;
  color: var(--white-color);
  cursor: pointer;
  border: none;
  border-radius: 0.3rem;
  font-size: 1.6rem;
}

.submit:hover {
  border: 0.05rem solid var(--white-color);
  background: unset;
}

.disabled {
  background-color: #c9c9c9;
  color: var(--black-color);
  pointer-events: none;
  cursor: not-allowed;
}

.contact-infos {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  animation: slideFromLeft 1s forwards;
}

.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--white-color);
}

.contact-info .contact-icon {
  font-size: 2rem;
  color: var(--yellow-color);
}

.contact-info label {
  font-size: 2rem;
  color: var(--white-color);
}

@media (max-width: 480px) {
  .contact-container {
    padding-top: calc(var(--navbar-size) + 10rem);
    gap: 1rem;
  }
  .contact-container form {
    width: 90%;
  }
  .fields-2 {
    flex-direction: column;
  }
  .fields-1 textarea {
    height: 5rem;
  }

  .contact-infos {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
  }
  .contact-nav-btn {
    margin-top: 1rem !important;
  }

  .contact-info .contact-icon {
    font-size: 1.5rem;
    color: var(--yellow-color);
  }

  .contact-info label {
    font-size: 1.5rem;
    color: var(--white-color);
  }
}

/* contact ends */

/* hft starts */

.propfirms-container {
  width: 100vw;
  padding: 2rem 10rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.propfirms-img {
  height: 60vh;
}

.pricing-table {
  color: var(--white-color);
  height: 60vh;
  width: 80%;
}

.pricing-table tr td {
  font-size: 2rem;
  padding: 1rem 2rem;
}

.pricing-table tr td:first-child {
  background-color: var(--blue-color);
  width: 30%;
}

.pricing-table tr td:last-child {
  border: 0.01rem solid #c9c9c9;
  font-weight: 500;
}

.propfirms,
.pricing {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.line {
  width: 0.1rem;
  height: 20rem;
  background-color: var(--white-color);
}

.payement {
  color: var(--white-color);
  font-size: 2rem;
  padding: 0rem 2rem;
  text-align: center;
}

.disclaimer {
  width: 4rem;
  margin-bottom: 1rem;
}

@media (max-width: 480px) {
  .propfirms-container {
    padding: 2rem;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }

  .payement {
    font-size: 1.7rem;
  }

  .propfirms-img {
    height: 40vh;
  }

  .propfirms,
  .pricing {
    width: 100%;
    height: fit-content;
    justify-content: flex-start;
  }
  .section-head-title-2 {
    margin: unset !important;
  }
  .line {
    width: 100%;
    height: 0.1rem;
  }
  .pricing-table {
    max-height: 40vh;
    height: unset;
    width: 100%;
  }

  .pricing-table tr td {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }
}

/* hft ends */

/* loading all */

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
}

.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  animation: spinner-plncf9 4s infinite;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  border: 9px solid;
  border-radius: 50%;
  border-color: #474bff #474bff #0000 #0000;
  mix-blend-mode: darken;
  animation: spinner-plncf9 1s infinite linear;
}

.spinner::after {
  border-color: #0000 #0000 #dbdcef #dbdcef;
  animation-direction: reverse;
}

@keyframes spinner-plncf9 {
  100% {
    transform: rotate(1turn);
  }
}
